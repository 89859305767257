// src/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Reset CSS */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  /* Body Styles */
  body {
    background-color: #f5f5f5;
    color: #2c3e50;
    scroll-behavior: smooth;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  /* Link Styles */
  a {
    text-decoration: none;
    color: inherit;
  }

  /* Focus Styles for Accessibility */
  a:focus, button:focus {
    outline: 2px dashed #dc4d01;
    outline-offset: 4px;
  }

  /* Optional: Scrollbar Styling */
  /* For Webkit Browsers */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

export default GlobalStyles;
