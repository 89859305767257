// src/App.js
import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ActivitiesSection from './components/ActivitiesSection';
import Events from './components/Events';
import Footer from './components/Footer';
import GlobalStyles from './GlobalStyles';
import MembersSection from './components/MemberSection';

function App() {
  return (
    <>
      <GlobalStyles />
      <Header />
      <HeroSection />
      <AboutSection />
      <ActivitiesSection />
      <MembersSection />
      <Events />
      <Footer />
    </>
  );
}

export default App;
