// src/components/ServicesSection.js
import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { FaHandsHelping, FaHeartbeat, FaGlobe } from 'react-icons/fa';

// Define hover colors for each service
const hoverColors = {
  community: '#3498db',
  health: '#e67e22',
  global: '#9b59b6',
};

// Styled Components
const ActivitiesContainer = styled.section`
  padding: 100px 50px;
  text-align: center;
  background: #f9f9f9;
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 60px;
  color: #2c3e50;
  position: relative;

  &:after {
    content: '';
    width: 100px;
    height: 4px;
    background: #e74c3c;
    display: block;
    margin: 20px auto 0;
    border-radius: 2px;
  }
`;

const ServicesGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
`;

const ServiceCard = styled(motion.div)`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Dynamic hover background color */
  ${(props) =>
    props.hoverColor &&
    css`
      &:hover {
        background: ${props.hoverColor};
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);

        h3 {
          color: #ffffff;
        }

        p {
          color: #f0f0f0;
        }

        svg {
          color: #ffffff;
          transform: scale(1.2);
        }
      }
    `}
`;

const IconWrapper = styled.div`
  font-size: 48px;
  color: #e74c3c;
  margin-bottom: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
`;

const ServiceTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
  color: #2c3e50;
  transition: color 0.3s ease;
`;

const ServiceDescription = styled.p`
  font-size: 16px;
  color: #7f8c8d;
  transition: color 0.3s ease;
`;

// Services Data with Hover Colors
const services = [
  {
    icon: <FaHandsHelping />,
    title: 'Community Support',
    description:
      'Providing resources and support to communities in need around the world.',
    hoverColor: hoverColors.community,
  },
  {
    icon: <FaHeartbeat />,
    title: 'Health Initiatives',
    description:
      'Promoting health and well-being through various medical relief programs.',
    hoverColor: hoverColors.health,
  },
  {
    icon: <FaGlobe />,
    title: 'Global Outreach',
    description:
      'Expanding our reach to make a positive impact on a global scale.',
    hoverColor: hoverColors.global,
  },
  // Add more services as needed, ensuring each has a unique hoverColor
  {
    icon: <FaHandsHelping />,
    title: 'Community Support',
    description:
      'Providing resources and support to communities in need around the world.',
    hoverColor: hoverColors.community,
  },
  {
    icon: <FaHeartbeat />,
    title: 'Health Initiatives',
    description:
      'Promoting health and well-being through various medical relief programs.',
    hoverColor: hoverColors.health,
  },
  {
    icon: <FaGlobe />,
    title: 'Global Outreach',
    description:
      'Expanding our reach to make a positive impact on a global scale.',
    hoverColor: hoverColors.global,
  },
  {
    icon: <FaHandsHelping />,
    title: 'Community Support',
    description:
      'Providing resources and support to communities in need around the world.',
    hoverColor: hoverColors.community,
  },
  {
    icon: <FaHeartbeat />,
    title: 'Health Initiatives',
    description:
      'Promoting health and well-being through various medical relief programs.',
    hoverColor: hoverColors.health,
  },
  {
    icon: <FaGlobe />,
    title: 'Global Outreach',
    description:
      'Expanding our reach to make a positive impact on a global scale.',
    hoverColor: hoverColors.global,
  },
];

const ActivitiesSection = () => {
  return (
    <ActivitiesContainer id="activities">
      <Title>Our Activities</Title>
      <ServicesGrid>
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            hoverColor={service.hoverColor}
            whileHover={{ scale: 1.05, y: -10 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <IconWrapper>{service.icon}</IconWrapper>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </ServicesGrid>
    </ActivitiesContainer>
  );
};

export default ActivitiesSection;
