// src/components/MemberModal.js
import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { FaTimes, FaEnvelope, FaPhone, FaMapMarkerAlt, FaBriefcase } from 'react-icons/fa';

// Ensure that react-modal knows the app element for accessibility
Modal.setAppElement('#root');

const ModalContent = styled.div`
  padding: 30px;
  position: relative;
  background-color: #ffffff;
  border-radius: 15px;
  text-align: left;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #7f8c8d;

  &:hover {
    color: #e74c3c;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const ModalTitle = styled.h2`
  font-size: 26px;
  margin: 0;
  color: #2c3e50;
`;

const ModalStatus = styled.p`
  font-size: 18px;
  color: #f39c12;
  margin: 5px 0 0 0;
`;

const ModalInfo = styled.p`
  font-size: 16px;
  color: #34495e;
  margin-bottom: 10px;

  strong {
    color: #2c3e50;
  }

  svg {
    color: #e74c3c;
    margin-right: 8px;
  }
`;

const ModalDescription = styled.p`
  font-size: 16px;
  color: #34495e;
  line-height: 1.6;
`;

const MemberModal = ({ isOpen, onRequestClose, member }) => {
  if (!member) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Member Details"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          border: 'none',
          borderRadius: '15px',
          maxWidth: '600px',
          width: '90%',
        },
      }}
    >
      <ModalContent>
        <CloseButton onClick={onRequestClose}>
          <FaTimes />
        </CloseButton>
        <ModalHeader>
          <ModalImage src={member.image} alt={`${member.name}'s photo`} loading="lazy" />
          <div>
            <ModalTitle>{member.name}</ModalTitle>
            <ModalStatus>{member.status}</ModalStatus>
          </div>
        </ModalHeader>
        <ModalInfo>
          <FaBriefcase />
          <strong>Current Job Place:</strong> {member.currentJobPlace}
        </ModalInfo>
        <ModalInfo>
          <FaMapMarkerAlt />
          <strong>Present Address:</strong> {member.presentAddress}
        </ModalInfo>
        <ModalInfo>
          <FaMapMarkerAlt />
          <strong>Permanent Address:</strong> {member.permanentAddress}
        </ModalInfo>
        <ModalInfo>
          <FaEnvelope />
          <strong>Email:</strong> {member.email}
        </ModalInfo>
        <ModalInfo>
          <FaPhone />
          <strong>Mobile:</strong> {member.mobile}
        </ModalInfo>
        <ModalDescription>{member.description}</ModalDescription>
      </ModalContent>
    </Modal>
  );
};

export default MemberModal;
