// src/components/Testimonials.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';

// Sample Data for Upcoming and Recent Events
const upcomingEvents = [
  {
    id: 1,
    image: 'https://via.placeholder.com/400x250.png?text=Upcoming+Event+1',
    title: 'Community Health Workshop',
    date: 'October 20, 2024',
    location: 'Downtown Community Center',
    description:
      'A comprehensive workshop focused on promoting health and wellness within our community. Featuring expert speakers and interactive sessions.',
    details: 'Detailed information about Community Health Workshop.',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/400x250.png?text=Upcoming+Event+2',
    title: 'Annual Charity Run',
    date: 'November 15, 2024',
    location: 'City Park',
    description:
      'Join us for our annual charity run to raise funds for local shelters. All fitness levels are welcome!',
    details: 'Detailed information about Annual Charity Run.',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/400x250.png?text=Upcoming+Event+3',
    title: 'Environmental Awareness Seminar',
    date: 'December 5, 2024',
    location: 'Greenwood Auditorium',
    description:
      'An insightful seminar dedicated to environmental conservation and sustainable living practices.',
    details: 'Detailed information about Environmental Awareness Seminar.',
  },
  // Add more upcoming events as needed
];

const recentEvents = [
  {
    id: 1,
    image: 'https://via.placeholder.com/400x250.png?text=Recent+Event+1',
    title: 'Winter Clothing Drive',
    date: 'January 20, 2024',
    location: 'Main Community Hall',
    description:
      'Successfully collected and distributed winter clothing to over 500 families in need. A heartfelt thank you to all volunteers!',
    details: 'Detailed information about Winter Clothing Drive.',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/400x250.png?text=Recent+Event+2',
    title: 'Spring Clean-Up',
    date: 'March 15, 2024',
    location: 'Riverfront Park',
    description:
      'Organized a community clean-up event, removing over 100 bags of trash from local parks. Together, we made a difference!',
    details: 'Detailed information about Spring Clean-Up.',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/400x250.png?text=Recent+Event+3',
    title: 'Educational Workshop Series',
    date: 'May 10, 2024',
    location: 'Community Learning Center',
    description:
      'Hosted a series of workshops focused on empowering youth through education and skill development.',
    details: 'Detailed information about Educational Workshop Series.',
  },
  // Add more recent events as needed
];

// Styled Components

const EventsContainer = styled.section`
  padding: 100px 50px;
  background-color: #ffffff;
  text-align: center;

  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  color: #2c3e50;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    width: 80px;
    height: 4px;
    background-color: #dc4d01;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 30px;

    &:after {
      width: 60px;
    }
  }
`;

const EventsSection = styled.div`
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const EventsTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 30px;
  color: #34495e;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const EventsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  justify-items: center;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const CarouselTrack = styled(motion.div)`
  display: flex;
  gap: 30px;
  will-change: transform;
`;

const EventCard = styled(motion.div)`
  background-color: #f8f9fa;
  border-radius: 12px;
  width: 350px;
  min-width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #e6f7ff; /* Changed hover background color */
  }

  @media (max-width: 1024px) {
    width: 300px;
    min-width: 300px;
  }

  @media (max-width: 768px) {
    width: 250px;
    min-width: 250px;
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 180px;
  }
`;

const EventContent = styled.div`
  padding: 20px;
  text-align: left;
`;

const EventTitle = styled.h4`
  font-size: 22px;
  margin-bottom: 10px;
  color: #2c3e50;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const EventDate = styled.p`
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: #dc4d01;
  }
`;

const EventLocation = styled.p`
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: #dc4d01;
  }
`;

const EventDescription = styled.p`
  font-size: 14px;
  color: #34495e;
  line-height: 1.5;
`;

const ViewDetailsButton = styled.a`
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #dc4d01;
  color: #ffffff;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;

// Animation Variants

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.2, duration: 0.6, ease: 'easeOut' },
  }),
};

// Testimonials Component

const Events = () => {
  // Duplicate recentEvents to enable seamless scrolling
  const duplicatedRecentEvents = [...recentEvents, ...recentEvents];

  return (
    <EventsContainer id="events">
      <Title>Our Events</Title>

      {/* Upcoming Events Section */}
      <EventsSection>
        <EventsTitle style={{ color: '#2c3e50' }}>Upcoming Events</EventsTitle>
        <EventsGrid
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {upcomingEvents.map((event, index) => (
            <EventCard
              key={event.id}
              custom={index}
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
            >
              <EventImage src={event.image} alt={event.title} />
              <EventContent>
                <EventTitle>{event.title}</EventTitle>
                <EventDate>
                  <FaCalendarAlt />
                  {event.date}
                </EventDate>
                <EventLocation>
                  <FaMapMarkerAlt />
                  {event.location}
                </EventLocation>
                <EventDescription>{event.description}</EventDescription>
                <ViewDetailsButton href="#!">View Details</ViewDetailsButton>
              </EventContent>
            </EventCard>
          ))}
        </EventsGrid>
      </EventsSection>

      {/* Completed Events Section */}
      <EventsSection>
        <EventsTitle style={{ color: '#16a085' }}>Completed Events</EventsTitle>
        <CarouselContainer>
          <CarouselTrack
            initial="hidden"
            animate="animate"
            variants={{
              animate: {
                x: '-50%',
                transition: {
                  x: {
                    repeat: Infinity,
                    repeatType: 'loop',
                    duration: 60, // Adjust for scrolling speed
                    ease: 'linear',
                  },
                },
              },
            }}
          >
            {duplicatedRecentEvents.map((event, index) => (
              <EventCard
                key={`${event.id}-${index}`} // Unique key for duplicated events
                custom={index}
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
              >
                <EventImage src={event.image} alt={event.title} />
                <EventContent>
                  <EventTitle>{event.title}</EventTitle>
                  <EventDate>
                    <FaCalendarAlt />
                    {event.date}
                  </EventDate>
                  <EventLocation>
                    <FaMapMarkerAlt />
                    {event.location}
                  </EventLocation>
                  <EventDescription>{event.description}</EventDescription>
                </EventContent>
              </EventCard>
            ))}
          </CarouselTrack>
        </CarouselContainer>
      </EventsSection>
    </EventsContainer>
  );
};

export default Events;
