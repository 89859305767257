// src/components/MemberCard.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaBriefcase } from 'react-icons/fa';

const Card = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const CardContent = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.h3`
  font-size: 20px;
  margin: 0 0 5px 0;
  color: #2c3e50;
`;

const MemberStatus = styled.p`
  font-size: 16px;
  margin: 0 0 15px 0;
  color: #f39c12;
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  flex: 1;
`;

const InfoItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    color: #e74c3c;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    color: #34495e;
  }
`;

const ViewDetailsButton = styled.button`
  background-color: #dc4d01;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  align-self: center;

  &:hover {
    background-color: #c84300;
  }
`;

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const MemberCard = ({ member, onViewDetails }) => {
  return (
    <Card
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 0.6, ease: 'easeOut' }}
      viewport={{ once: true, amount: 0.2 }}
    >
      <CardImage src={member.image} alt={`${member.name}'s photo`} loading="lazy" />
      <CardContent>
        <MemberName>{member.name}</MemberName>
        <MemberStatus>{member.status}</MemberStatus>
        <InfoList>
          <InfoItem>
            <FaBriefcase />
            <span>{member.currentJobPlace}</span>
          </InfoItem>
          <InfoItem>
            <FaMapMarkerAlt />
            <span>{member.presentAddress}</span>
          </InfoItem>
          <InfoItem>
            <FaEnvelope />
            <span>{member.email}</span>
          </InfoItem>
          <InfoItem>
            <FaPhone />
            <span>{member.mobile}</span>
          </InfoItem>
        </InfoList>
        <ViewDetailsButton onClick={onViewDetails}>View Details</ViewDetailsButton>
      </CardContent>
    </Card>
  );
};

export default MemberCard;
