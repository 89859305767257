// src/components/AboutSection.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutContainer = styled.section`
  padding: 100px 50px;
  background-color: #f8f9fa;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  color: #2c3e50;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  color: #7f8c8d;
  line-height: 1.6;
`;

const AboutSection = () => {
  return (
    <AboutContainer id="about">
      <Title>About Us</Title>
      <Content
        as={motion.div}
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <p>
          We are dedicated to improving lives through compassionate action and
          impactful programs. Our organization works globally to address critical
          issues and foster sustainable change.
        </p>
        <p>
          Join us in our mission to make the world a better place for all.
        </p>
      </Content>
    </AboutContainer>
  );
};

export default AboutSection;
