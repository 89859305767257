// src/components/MembersSection.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import MemberCard from './MemberCard';
import MemberModal from './MemberModal';

// Sample Data for Members
const members = [
  {
    id: 1,
    image: 'https://via.placeholder.com/400x250.png?text=Member+1',
    name: 'John Doe',
    currentOrganization: 'ABC Corp',
    status: 'Job',
    currentJobPlace: 'Senior Developer at ABC Corp',
    presentAddress: '123 Main St, Cityville',
    permanentAddress: '456 Elm St, Townsville',
    email: 'john.doe@example.com',
    mobile: '+1 (555) 123-4567',
    description:
      'John is a seasoned developer with over 10 years of experience in full-stack development. He specializes in React and Node.js, and is passionate about building scalable web applications.',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/400x250.png?text=Member+2',
    name: 'Jane Smith',
    currentOrganization: 'XYZ Inc',
    status: 'Business',
    currentJobPlace: 'Founder at XYZ Inc',
    presentAddress: '789 Oak St, Villageville',
    permanentAddress: '321 Pine St, Hamletville',
    email: 'jane.smith@example.com',
    mobile: '+1 (555) 987-6543',
    description:
      'Jane is an entrepreneur passionate about sustainable business practices and community development. She founded XYZ Inc to provide eco-friendly solutions to everyday problems.',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/400x250.png?text=Member+3',
    name: 'Alice Johnson',
    currentOrganization: 'University of Education',
    status: 'Student',
    currentJobPlace: 'Computer Science Student at University of Education',
    presentAddress: '654 Maple St, Metropolis',
    permanentAddress: '987 Cedar St, Smalltown',
    email: 'alice.johnson@example.com',
    mobile: '+1 (555) 456-7890',
    description:
      'Alice is a dedicated student focusing on artificial intelligence and machine learning technologies. She is involved in several research projects and aims to contribute to advancements in AI.',
  },
  {
    id: 4,
    image: 'https://via.placeholder.com/400x250.png?text=Member+4',
    name: 'Bob Williams',
    currentOrganization: 'Freelancer',
    status: 'Other',
    currentJobPlace: 'Freelance Graphic Designer',
    presentAddress: '159 Birch St, Bigcity',
    permanentAddress: '753 Spruce St, Littletown',
    email: 'bob.williams@example.com',
    mobile: '+1 (555) 654-3210',
    description:
      'Bob is a creative graphic designer with a knack for visual storytelling and brand identity. He has worked with numerous clients to create compelling designs that resonate with audiences.',
  },
  // Add more members as needed
];

// Styled Components

const MembersContainer = styled.section`
  padding: 100px 50px;
  background-color: #f8f9fa;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  color: #2c3e50;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    width: 80px;
    height: 4px;
    background-color: #dc4d01;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 30px;

    &:after {
      width: 60px;
    }
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const CarouselTrack = styled(motion.div)`
  display: flex;
  width: calc(300px * 12); /* Adjust based on number of duplicated items */
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? 'left: 10px;' : 'right: 10px;')}
  transform: translateY(-50%);
  background-color: rgba(220, 77, 1, 0.7);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  z-index: 10;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(220, 77, 1, 1);
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const MembersSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const openModal = (member) => {
    setSelectedMember(member);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedMember(null);
    setModalIsOpen(false);
  };

  // Duplicate members for seamless scrolling
  const duplicatedMembers = [...members, ...members, ...members];

  // Animation Variants
  const carouselVariants = {
    animate: {
      x: ['0%', '-50%'],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: 30, // Adjust for scrolling speed
          ease: 'linear',
        },
      },
    },
  };

  return (
    <MembersContainer id="members">
      <Title>Our Members</Title>
      <CarouselContainer>
        <CarouselTrack
          variants={carouselVariants}
          initial="hidden"
          animate="animate"
        >
          {duplicatedMembers.map((member, index) => (
            <MemberCard
              key={`${member.id}-${index}`}
              member={member}
              onViewDetails={() => openModal(member)}
            />
          ))}
        </CarouselTrack>
        {/* Optional: Arrow Buttons for Manual Control */}
        {/* <ArrowButton left>
          <FaArrowLeft />
        </ArrowButton>
        <ArrowButton>
          <FaArrowRight />
        </ArrowButton> */}
      </CarouselContainer>

      {/* Member Details Modal */}
      {selectedMember && (
        <MemberModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          member={selectedMember}
        />
      )}
    </MembersContainer>
  );
};

export default MembersSection;
