// src/components/Footer.js
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from 'react-icons/fa';

// Styled Components

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 60px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  @media (max-width: 768px) {
    padding: 40px 30px;
  }
`;

const FooterSection = styled.div`
  flex: 1 1 250px;
  margin: 20px;
  
  @media (max-width: 768px) {
    flex: 1 1 100%;
    margin: 10px 0;
  }
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 20px;
  color: #ecf0f1;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.6;
`;

const QuickLinks = styled.ul`
  list-style: none;
  padding: 0;
`;

const QuickLink = styled.li`
  margin-bottom: 10px;
`;

const FooterLink = styled.a`
  color: #ecf0f1;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const ContactText = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`;

const SocialLink = styled.a`
  color: #ecf0f1;
  font-size: 18px;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  padding: 10px 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #e74c3c;
  }
`;

const TextArea = styled.textarea`
  padding: 10px 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #e74c3c;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 20px;
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #ffffff;
  }
`;

const SuccessMessage = styled.p`
  color: #2ecc71;
  font-size: 14px;
  margin-top: 10px;
`;

const FooterBottom = styled.div`
  width: 100%;
  border-top: 1px solid #34495e;
  padding-top: 20px;
  text-align: center;
  font-size: 13px;
`;

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [success, setSuccess] = useState(false);

  const { name, email, message } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (success) setSuccess(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic validation
    if (name.trim() === '' || email.trim() === '' || message.trim() === '') {
      alert('Please fill in all fields.');
      return;
    }

    // Here you can integrate with a backend service or email API
    // For demonstration, we'll just reset the form and show a success message
    console.log('Form Submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
    setSuccess(true);
  };

  return (
    <FooterContainer id="contact">
      {/* About Us Section */}
      <FooterSection>
        <SectionTitle>About Us</SectionTitle>
        <Description>
          We are dedicated to making a positive impact in our community through various initiatives and programs. Join us in our mission to create a better future for all.
        </Description>
      </FooterSection>

      {/* Quick Links Section */}
      <FooterSection>
        <SectionTitle>Quick Links</SectionTitle>
        <QuickLinks>
          <QuickLink>
            <FooterLink href="#home">Home</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#about">About Us</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#activities">Activities</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#members">Members</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#events">Events</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#contact">Contact</FooterLink>
          </QuickLink>
        </QuickLinks>
      </FooterSection>

      {/* Contact Information Section */}
      <FooterSection>
        <SectionTitle>Contact Us</SectionTitle>
        <ContactInfo>
          <FaMapMarkerAlt />
          <ContactText>1234 Street Name, City, State, 56789</ContactText>
        </ContactInfo>
        <ContactInfo>
          <FaPhoneAlt />
          <ContactText>(123) 456-7890</ContactText>
        </ContactInfo>
        <ContactInfo>
          <FaEnvelope />
          <ContactText>info@yourorganization.org</ContactText>
        </ContactInfo>
        <SocialIcons>
          <SocialLink href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebookF />
          </SocialLink>
          <SocialLink href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </SocialLink>
          <SocialLink href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </SocialLink>
          <SocialLink href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <FaLinkedinIn />
          </SocialLink>
        </SocialIcons>
      </FooterSection>

      {/* Contact Form Section */}
      <FooterSection>
        <SectionTitle>Send Us a Message</SectionTitle>
        <ContactForm onSubmit={handleSubmit}>
          <InputField
            type="text"
            name="name"
            placeholder="Your Name"
            value={name}
            onChange={handleChange}
            aria-label="Your Name"
            required
          />
          <InputField
            type="email"
            name="email"
            placeholder="Your Email"
            value={email}
            onChange={handleChange}
            aria-label="Your Email"
            required
          />
          <TextArea
            name="message"
            placeholder="Your Message"
            value={message}
            onChange={handleChange}
            aria-label="Your Message"
            required
          />
          <SubmitButton type="submit">Send Message</SubmitButton>
          {success && <SuccessMessage>Your message has been sent successfully!</SuccessMessage>}
        </ContactForm>
      </FooterSection>

      {/* Footer Bottom */}
      <FooterBottom>
        &copy; {new Date().getFullYear()} Your Organization. All rights reserved.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
