// src/components/HeroSection.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import heroImage from '../assets/hero-image2.jpg'; // You need to add an image in src/assets/

const HeroContainer = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${heroImage}) center/cover no-repeat;
  position: relative;
  margin-top: 60px; /* Height of the header */
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(44, 62, 80, 0.6);
`;

const Content = styled(motion.div)`
  position: relative;
  color: #ecf0f1;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 30px;
`;

const CTAButton = styled(motion.a)`
  padding: 15px 30px;
  background-color: #e74c3c;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
`;

const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <Overlay />
      <Content
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Title>Making a Difference Together</Title>
        <Subtitle>
          Join us in our mission to create a better world for everyone.
        </Subtitle>
        <CTAButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          href="#about"
        >
          Learn More
        </CTAButton>
      </Content>
    </HeroContainer>
  );
};

export default HeroSection;
